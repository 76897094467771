import React from "react";
import { Col, Tabs, Tab, Row, Dropdown } from "react-bootstrap";
import "../../../styles/components/Blog/Category/style.scss"

const BlogCategory = ({ data, children, selectedCategory, setSelectedCategory }) => {
  const categoryHandler = (category) => {
    setSelectedCategory(category);
  };
  return (
    <>
      <Row className="blog__category">
        <Col>
          <Dropdown className="d-block d-md-none">
            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
              {selectedCategory}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {data.map((category) => {
                return (
                  <Dropdown.Item onClick={() => categoryHandler(category)}>
                    {category}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>

          <Tabs
            id="blog_tab_category"
            activeKey={selectedCategory}
            onSelect={(k) => categoryHandler(k)}
            className="d-none d-md-flex"
          >
            {data.map((category) => {
              return <Tab eventKey={category} title={category}></Tab>;
            })}
          </Tabs>
          {children}
        </Col>
      </Row>
    </>
  );
};

export default BlogCategory;
